import React, { useState, useEffect, useMemo } from "react";

import { Button } from "./button";
import { Input } from "./input";

import { useCodes } from "../../hooks/useCodes";
import { useAuth } from "../../hooks/useAuth";
import { useUsers } from "../../hooks/useUsers";

import closeImg from "../../assets/close.webp";

import doddles from "../../assets/doddles.webp";

export const Signup = ({ onClose }) => {
  const [email, onChangeEmail] = useState("");
  const [password, onChangePassword] = useState("");
  const [invitationCode, onChangeInvitationCode] = useState("");
  const [name, onChangeName] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  const emailRegex = useMemo(() => /^[^\s@]+@[^\s@]+\.[^\s@]+$/, []);

  const { validateCode } = useCodes();
  const { signup, saveSession } = useAuth();
  const { createUser, getUser } = useUsers();

  useEffect(() => {
    setIsFormValid(
      emailRegex.test(email) &&
        password.length >= 6 &&
        invitationCode.length > 10 &&
        name.length > 2
    );
  }, [emailRegex, email, password, invitationCode, name]);

  const runSignup = async () => {
    const dataCode: any = await validateCode(invitationCode);
    if (!dataCode || !dataCode.valid) {
      window.alert(
        "Codigo de invitación invalido. Verificalo y vuelve a intentarlo."
      );
      return;
    }
    const token = await signup(email, password);
    if (!token) window.alert("Lo sentimos algo salió mal, intenta más tarde.");

    await createUser(name, invitationCode, token);
    await getUser();
    await saveSession();
  };

  return (
    <div className='flex justify-center absolute w-[100vw] h-[100vh] bg-gray-400 z-50 bg-[rgba(6,20,37,0.9)]'>
      <div
        className='absolute w-[90vw] h-[70vh] z-0 repeat opacity-[0.03] rounded-2xl mt-[10vh]'
        style={{ backgroundImage: `url(${doddles})` }}></div>
      <div className='flex flex-col w-[90vw] h-[70vh] bg-[rgb(23,43,69)] rounded-2xl p-5 mt-[10vh]'>
        <div className='w-full flex justify-end z-30'>
          <img
            src={closeImg}
            alt='close'
            width={16}
            height={16}
            onClick={onClose}
          />
        </div>
        <div className='flex w-full justify-center mt-3'>
          <span className='text-3xl'>Registro</span>
        </div>

        <div className='flex flex-col items-center w-full flex-1 mt-5'>
          <Input
            value={email}
            onChange={(event: any) => onChangeEmail(event.target.value)}
            label='Correo'
            autoComplete='off'
          />
          <Input
            value={password}
            onChange={(event: any) => onChangePassword(event.target.value)}
            label='Contraseña'
            password
          />

          <Input
            value={name}
            onChange={(event: any) => onChangeName(event.target.value)}
            label='Nombre'
          />
          <Input
            value={invitationCode}
            onChange={(event: any) =>
              onChangeInvitationCode(event.target.value)
            }
            label='Codigo de invitación'
          />
        </div>
        <div className='flex justify-center z-30'>
          <Button
            label={"Crear cuenta"}
            onClick={runSignup}
            disabled={!isFormValid}
          />
        </div>
      </div>
    </div>
  );
};
